<div class="select-wrapper" [class.active]="options.active" [class.error]="error" [class.error2]="error2">
  <div class="select" data-value="" (click)="changeActive()" #select>
    <span>
      @if (leftImage) {
      <img alt="" [src]="leftImage" width="18px" height="29px">
      }
      <p [style.color]="choice?'black':'white'">{{choice}}</p>
    </span>
    <img alt="" class="button-arrow no-select" src="/assets/icons/select-arrow.svg" width="20px" height="20px">
  </div>
  @if(options) {
  <div [style.top]="bottom?'initial': options.active?-topHeight-40+'px':'-40px'"
    [style.height]="options.active?topHeight+10+'px':0" class="options__wrapper" [@Select]="this.options.status">
    <ul class="options">
      @for (option of options.values; track $index) {
      <li [style.padding-left]="leftImage?'32px':'5px'" [style]="'height:'+(subtext?60:40)+'px'"
        (click)="buttonClick(option,options.subValues[$index])">
        <p>{{option}}</p>
        @if(subtext) {
        <small>{{options.subValues[ $index]}}</small>
        }
      </li>
      }
    </ul>
  </div>
  }
</div>