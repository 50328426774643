import { Component, ElementRef, Inject, Input, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { InputComponent } from "../input/input.component";
import { NgxMaskDirective } from 'ngx-mask';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PopupService } from '../../services/pupup.service';
import { customPatternValid } from '../../services/custom-pattern-valid';
import { sendMailService } from '../../services/send-mail.service';
import { NotificationService } from '../notifications/notifications.component';
import { Observable } from 'rxjs';
import { ProductForForm } from '../../data/goods/new-goods.class';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
declare var ym: any;
@Component({
  selector: 'app-popup-form',
  standalone: true,
  imports: [InputComponent, NgxMaskDirective, ReactiveFormsModule],
  templateUrl: './popup-form.component.html',
  styleUrl: './popup-form.component.scss'
})
export class PopupFormComponent {
  isMobileDevice(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = this.document.defaultView?.navigator.userAgent.toLowerCase();
      // Пример условия для определения мобильного устройства
      return /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent || '');
    }
    return false;
  }
  @Input() customText?:boolean;
  @Input() good?: ProductForForm;
  @Input() anotherData?: Array<string> = [];
  requestForm: FormGroup;
  submit: boolean = false;
  constructor( @Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private mailService: sendMailService,
    private popupService: PopupService) {
      window.addEventListener('resize', () => {
        let width = window.innerWidth;
        let height = window.innerHeight;
        //console.log(`Ширина viewport: ${width}, Высота viewport: ${height}`);
    });
    this.requestForm = this.fb.group({
      phone: ['', [Validators.required, customPatternValid({ pattern: "phone" }),]],
    },);
  }
 
  get _phone() { return this.requestForm.get('phone') }
  scroll() {
    if(this.isMobileDevice()) {
      const form_top = this.renderer.selectRootElement('#request--form', true);
      setTimeout(()=>  {form_top.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },300);
    }
  }
  onSubmit() {
    this.submit = true;
    if (this.requestForm.valid) {
      let form: { phone: string } = this.requestForm.value;
      let formattedPhone = form.phone.replace(/[^0-9]/g, "");
      this.submit = false;
      let request: Observable<any>;
      if (this.good) {
        request = this.mailService.shopMessage({
          phone: formattedPhone,
          cart: JSON.stringify(this.good)
        });
      } else if (this.anotherData) {
        request = this.mailService.anotherMessage({ phone: formattedPhone, props: this.anotherData });
      }
      else {
        request = this.mailService.requestMessage({
          phone: formattedPhone,
        });
      }
      request.subscribe({
        next: data => {
          if (data['STATUS'] == 'OK') {
            //ym(95557011, 'reachGoal', 'send_online_form');
            this.requestForm.reset();
            this.notificationService.showNotification('Заявка успешно отправлена!', 'В ближайшее время мы с вами свяжемся, ожидайте звонок.', 'success-center', 3000);
            this.popupService.closeAll();
          }
          else {
            this.notificationService.showNotification('Возникла ошибка', data['ERROR'] ?? "Причина не известна", 'error', 3000);
          }
        },
        error: e => {
          this.notificationService.showNotification(e.error['title']?? 'Возникла ошибка!', e.error['text']?? 'Причина не известна', 'error', 3000);
        }
      })
    }
  }
}
