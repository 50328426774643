
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CatalogCard } from '../../../data/catalog-card.class';
import { PopupService } from '../../../services/pupup.service';
import { ItemComponent } from "../../item/item.component";
import { PopupFormComponent } from "../../popup-form/popup-form.component";
import { style } from '@angular/animations';
import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-catalog-card',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './catalog-card.component.html',
  styleUrl: './catalog-card.component.scss'
})
export class CatalogCardComponent {
  @Input() card?: CatalogCard;
  constructor(private popupService: PopupService, private productService: ProductsService) { }
  clickButton(props: string[]) {
    if (props[0] == "item-popup") {
      const contentComponent = CatalogItemWrapper;
      this.productService.setLoadingStatus({ id: props[1].toString(), status: false });
      this.popupService.open(contentComponent, { id: props[1] });
    } else if (props[0] == "item-form") {
      const contentComponent = PopupItemWrapper;

      this.popupService.open(contentComponent, { data: [props[0],this.card?.title], style: "dark" });
    } else if (props[0] == "category") {
      const contentComponent = PopupItemWrapper;
      this.popupService.open(contentComponent, { data: props, style: "dark" });
    }
  }
}
@Component({
  standalone: true,
  selector: 'app-bottom-interested-form',
  template: `<app-item [id]="id">`,
  imports: [
    ItemComponent
  ]
}) export class CatalogItemWrapper {
  @Input() id?: string;  // Получаем данные через Input
}
@Component({
  standalone: true,
  selector: 'app-bottom-interested-form',
  template: `<app-popup-form [style]="style" [anotherData]="data" [customText]="customText">`,
  imports: [
    ItemComponent,
    PopupFormComponent
  ]
}) export class PopupItemWrapper {
  @Input() id?: string;  // Получаем данные через Input
  @Input() data?: string[];  // Получаем данные через Input
  @Input() customText?: boolean;
  @Input() style?: string;  // Получаем данные через Input
}


@Component({
  standalone: true,
  selector: 'app-video-popup',
  template: `<video #video src="{{src}}" style="max-width: 100%;" controls autoplay></video>`,
  imports: [
    ItemComponent,
    PopupFormComponent
  ]
}) export class VideoComponentWrapper {
  
  @Input() src?: string;  // Получаем данные через Input
}