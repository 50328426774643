import { IGoodsCard } from "../goods/goods.interface";


export class Vendor {
  name: string;
  imageUrl: string;
  link: string;
  id: number = 0;
  constructor(name: string, link: string, imageUrl: string) {
    this.name = name;
    this.link = link;
    this.imageUrl = imageUrl;
  }
}
