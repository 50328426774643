import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpHeaders,
} from '@angular/common/http';
import { Observable, of, ReplaySubject } from "rxjs";
let httpOptions: { headers: HttpHeaders };
httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
	providedIn: 'root'
})

export class sendMailService {
	//private url = "https://rvd52.ru/api/mail.php";
	url = "api/mail";
	constructor(private http: HttpClient) {
	}
	shopMessage(data: { phone: string, cart: string }): Observable<any> {
		let command = "shopMessage";
		//return of({ STATUS: "ERROR" });
		return this.http.post(this.url, {
			'command': command, Headers,
			data,
		}, httpOptions);
	}
	requestMessage(data: { phone: string }): Observable<any> {
		let command = "requestMessage";
		//return of({ STATUS: "ERROR" });
		return this.http.post(this.url,
			{
				'command': command, Headers,
				data,
			},
			httpOptions);

	}
	anotherMessage(data: {phone:string,props:string[]}): Observable<any> {
		let command = "anotherMessage";
		//return of({ STATUS: "ERROR" });
		return this.http.post(this.url,
			{
				'command': command, Headers,
				data,
			},
			httpOptions);

	}

}
