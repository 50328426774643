import { vendors } from "../../constants/vendors";
import { SeoDescription } from "../seo/seo.class";
import { Select, Table, Vendor } from "./goods.class";

export class Preferences {
    type: "vendor" | "table" | "fiting" | "null" | "length";
    tableCol?: number;
    constructor(data: { type: "vendor" | "table" | "fiting", tableCol?: number }) {
        this.type = data.type;
        this.tableCol = data.tableCol;
    }
}
export const VendorPref = new Preferences({ type: "vendor" });
export function TablePref(id: number): Preferences {
    return new Preferences({ type: "table", tableCol: id });
}
export class ProductProps {
    name: string = "";
    text: string  = "";
    type:"vendor" | "table" | "fiting" | "length"| "null" | "string" = "null";
    constructor(name:string,text:string,type:"vendor" | "table" | "fiting" | "length" | "null" | "string") {
      if (name)
      this.name = name;
      if(text)
      this.text = text;
      this.type = type;
    }
  }
export class ProductForForm {
    name:string;
    props: ProductProps [];
    constructor (name:string, props:ProductProps[]) {
        this.name = name;
        this.props = props;
    }
}
export class Product {
    id: number = 0;
    imageURL: string;
    name: string;
    specification: string;
    usage: string;
    vendors: Vendor[] = vendors;
    seo: SeoDescription = new SeoDescription({});
    table?: Table;
    buyPreferences: Array<Preferences> = [VendorPref, TablePref(3)];

    constructor(data: { name: string, specification: string, usage: string, imageURL: string, vendors: Vendor[], buyPreferences?: Array<Preferences>, table?: Table, seo?: SeoDescription }) {
        this.imageURL = data.imageURL;
        this.name = data.name;
        this.specification = data.specification;
        this.usage = data.usage;
        this.vendors = data.vendors ?? data?.vendors;;
        if (data.buyPreferences) {
            this.buyPreferences = data.buyPreferences;
        }
        this.table = data.table;
    }


    generateSelects(): Array<Select> {
        let temp = new Array<Select>();
        if (this.buyPreferences) {
            this.buyPreferences.forEach(pref => {
                temp.push(Select.generateSelect(pref.type, { table: this.table, column: pref.tableCol, vendors: this.vendors }));
            });
        }
        return temp;
    }
}