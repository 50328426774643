
import { Component, ElementRef, EventEmitter, Input, Output, Inject, PLATFORM_ID, forwardRef, ViewChild } from '@angular/core';
import { isPlatformBrowser, NgForOf, NgIf } from "@angular/common";
import { fromEvent, Subscription } from "rxjs";
import { animate, state, style, transition, trigger } from "@angular/animations";

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Select } from '../../data/goods/goods.class';

@Component({
  standalone: true,
  selector: 'c-select',
  templateUrl: './select.component.html',
  imports: [
    NgIf,
    NgForOf
  ],
  styleUrls: ['./select.component.scss'],
  animations: [
    trigger('Select', [
      state("active", style({
        'display': 'block', 'z-index': 2,
      })),
      state("transform", style({
        'display': 'block', 'z-index': 2,
      })),
      state("inactive", style({
        'display': 'none', height: 0,
      })),
      transition('active => transform', animate('0.3s ease-out')),
      transition('transform => inactive', animate('0s')),
      transition('inactive => transform', animate('0s')),
      transition('transform => active', animate('0.3s ease-in'))
    ])
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectComponent,
    multi: true
  }]
})

export class SelectComponent implements ControlValueAccessor {
  @Input() options: Select;
  @Input() subtext: boolean = false;
  @Input() leftImage: string = "";
  @Input() error: boolean | undefined = false;
  @Input() error2: boolean | undefined = false;
  choice: string = "";
  @Output() value = new EventEmitter<string>();
  @Output() subValue = new EventEmitter<string>();
  @Output() change = new EventEmitter();
  active: boolean = false;
  @ViewChild("select", { static: false }) select!: ElementRef;
  constructor(private eRef: ElementRef, @Inject(PLATFORM_ID) private platformId: any) {
    this.options = new Select();
  }
  private clickSubscription: Subscription | undefined;
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.options.values.push("Не знаю");
      this.clickSubscription = fromEvent(document, "click").subscribe(event => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          if (this.options.status == "active")
            this.changeActive(false);

        } else {
          // ... click inside
        }
      });
    }
  }
  topHeight: number = 0;
  bottom: boolean = false;
  changeActive(status?: boolean) {
    if (this.options.values.length > 0)
      if (status == false || (this.options.status == "active" && this.options.active)) {
        this.options.status = "transform";
        this.options.active = false;
        setTimeout(() => { this.options.status = "inactive"; this.onTouched() }, 300);
      }
      else if (status == true || (this.options.status == "inactive" && !this.options.active)) {
        this.options.status = "transform";
        this.calculateHeight();
        setTimeout(() => { this.options.status = "active"; this.options.active = true; }, 1);
      }
  }

  calculateHeight() {
    const parentElement = this.select.nativeElement;

    if (parentElement) {
      const parentRect = parentElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const availableHeight = parentRect.top;

      // Установите максимальную высоту для дочернего элемента
      if (availableHeight > 200) {
        this.bottom = false;
        this.topHeight = Math.min(availableHeight - 120, Math.min(500, this.options.values.length * (this.subtext ? 60 : 40)));
      }
      if (availableHeight < 200) {
        this.bottom = true;
        const availableBottom = viewportHeight - parentRect.top - 70;
        this.topHeight = Math.min(availableBottom, Math.min(500, this.options.values.length * (this.subtext ? 60 : 40)));
      }
    }


  }


  onChange = (choice: string) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  buttonClick(value: string, subtext?: string) {

    if (!this.disabled) {
      this.value.emit(value);
      if (subtext)
        this.subValue.emit(subtext);
      this.change.emit();
      this.choice = value;
      this.changeActive();
    }
    this.onChange(this.choice);
  }

  writeValue(choice: string) {

    this.value.emit(choice);
    this.change.emit();
    this.choice = choice;
    this.onChange(this.choice);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }


  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnDestroy(): void {
    this.clickSubscription?.unsubscribe();
  }

}
