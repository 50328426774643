import { AbstractControl, ValidatorFn } from "@angular/forms";

export function customPatternValid(config: { pattern: string, msg?: string }): ValidatorFn {
    return (control: AbstractControl) => {
        let controlValue = control.value;
        if (config.pattern === "phone") {
            if (controlValue) {

                if (controlValue.replace(/[^0-9]/g, "").length !== 10) {

                    return { invalidMsg: "Телефон слишком короткий" };
                }
            }
        }
        if (config.pattern === "name") {
            const nameRegEx = /^([А-ЯЁа-яё]+|[A-Za-z]+)$/;
            if (controlValue && !controlValue.match(nameRegEx)) {
                return { invalidMsg: "неправильно" };
            }
        }
        if (config.pattern == "fullName") {
            const nameRegEx = /^([а-яa-zё]+-?[а-яa-zё]+)( [а-яa-zё]+-?[а-яa-zё]+){0,2}$/;
            if (controlValue && !controlValue.toLowerCase().match(nameRegEx) && controlValue.length > 0) {
                return { invalidMsg: "неправильно" };
            }
        }
        if (config.pattern === "age") {
            const nameRegEx = /^([0-9]+)$/;
            if (controlValue && !controlValue.match(nameRegEx)) {
                return { invalidMsg: "неправильно" };
            }
            if (controlValue < 18 || controlValue > 99)
                return { invalidMsg: "неправильно" };
        }
        if (config.pattern == "email") {
            const expression: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
            if (controlValue && !expression.test(controlValue) && controlValue.length > 0) {
                return { invalidMsg: "неправильно" };
            }
        }
        if (config.pattern === "length") {
            if (controlValue) {
                if (+controlValue < 10 || +controlValue >= 1000) {
                    return { length: "error" };
                }
            }
        }
        if (config.pattern === "count") {
            if (controlValue) {
                if (+controlValue <= 0) {
                    return { count: "error" };
                }
            }
        }
        return null;
    };
}