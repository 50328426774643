import { Component, Inject, Input, OnDestroy, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Select } from '../../data/goods/goods.class';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SeoService } from '../../services/seo.service';
import { switchMap } from 'rxjs';
import { SelectComponent } from "../select/select.component";
import { PopupService } from '../../services/pupup.service';
import { PopupFormComponent } from '../popup-form/popup-form.component';
import { Product, ProductForForm, ProductProps } from '../../data/goods/new-goods.class';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'app-item',
  standalone: true,
  imports: [SelectComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ItemComponent implements OnDestroy {
  isLoading = false;
  formGenerated: boolean = false;

  submit = false;
  product: Product | undefined;
  selects: Array<Select> = [];
  clientForm!: FormGroup;
  @Input() id?: string;

  ngOnInit() {
    if (this.id) {
      this.loadProduct(+this.id);
    }


  }
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, @Inject(DOCUMENT) document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private seo: SeoService, private renderer2: Renderer2,
    private popupService: PopupService,
    private productService:ProductsService,
  ) {
    this.activatedRoute.paramMap.pipe(switchMap(params => params.getAll('id')))
      .subscribe(data => {
        this.loadProduct(+data);
      });
  }
  ngOnDestroy() { }

  openPopup() {
    this.submit = true;
    if (this.clientForm.valid) {
      let information = new Array<ProductProps>();
      if (this.product) {
        let item;
        for (let i = 0; i < this.selects.length; i++) {
          item = this.selects[i];
          let selectValue = this.clientForm.get('select' + i)?.value;
          information.push(new ProductProps(item.title, selectValue, item.type));
        }
        this.submit = false;
        this.clientForm.reset();
        this.clientForm.markAsUntouched();
        this.clientForm.markAsPristine();
        const contentComponent = AnswersComponentGG;
        // Передаем новое значение в компонент
        this.popupService.open(contentComponent, { style: "dark", good: new ProductForForm(this.product.name, information) });
      }
    }
  }


  tableOpen = false;
  openTable() {
    this.tableOpen = !this.tableOpen;
  }
  generateForm() {
    this.clientForm = this.fb.group({});
    for (let i = 0; i < this.selects.length; i++) {
      //this.clientForm.addControl('select' + i, new FormControl("", [this.selects[i].type != "vendor" ? Validators.required : Validators.nullValidator]));
      this.clientForm.addControl('select' + i, new FormControl("", []));
    }

    this.formGenerated = true;
  }
  controlValue(name: string) {
    return this.clientForm.get(name);
  }
  loadProduct(id: number) {
    
    this.productService.getLoadedProducts(id.toString()).subscribe((data:Product) => {

    //this.bd.getDataById(id).subscribe((data: Product) => {
      this.product = data;
      if (this.product) {
        this.seo.updateTitle(`РВД-Сервис | Каталог - ${data.name}`);
        this.seo.updateDescription(data.seo.description);
        this.seo.updateKeywords("РВД, РВД по типам, РВД лесозаготовительные, РВД напорно всасывающие, РВД для спецтехники, РВД для сельхозтехники, РВД для автоматической гидравлики, РВД для химической промышленности, Фитинги, Муфты, БРС, защита РВД, РВД 1SN, 2SN, 2SN-C, 2SN-K, R13/15, PTFE , РВД Нижний Новгород")
        // this.seo.updateSchema(this.renderer2, "");
        this.selects = [];

        this.product.buyPreferences.forEach(temp => {
          if (temp.type == "vendor" && this.product!.vendors) {
            this.selects.push(Select.fromVendors(this.product!.vendors));
          }
          if (temp.type == "fiting") {
            this.selects.push(Select.fromFitings());
          }
          if (this.product?.table && typeof temp.tableCol == "number" && temp.type == "table") {
            this.selects.push(Select.fromTables(this.product.table, temp.tableCol));
          }

        })
        this.generateForm();
        this.isLoading = true;
        this.productService.setLoadingStatus({id:id.toString(),status:true});
        return;
      }
    },
    e => {
      this.isLoading = true;
      this.productService.setLoadingStatus({id:id.toString(),status:false});

    }
  )
  }


}

@Component({
  standalone: true,
  selector: 'app-bottom-interested-form',
  template: `<app-popup-form [good]="good">`,
  imports: [
    PopupFormComponent,
  ]
}) export class AnswersComponentGG {
  @Input() good?: ProductForForm;  // Получаем данные через Input
}