<div class="notifications">
    @for (notification of notifications$ | async; track notification.id) {
    <div class="notification notification--{{ notification.type }}">
        <span class="notification__icon" [innerHTML]="getIcon(notification.type)"></span>
        <div class="notification__content">
            <div class="notification__title">{{notification.title}}</div>
            <div class="notification__message">{{notification.message}}</div>
        </div>
    </div>
    }
</div>