import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
    providedIn: 'root',
})
export class PopupService {
    private popups: { component: any, data: any, id: string }[] = [];
    private popupContext = new BehaviorSubject<{ component: any, data: any, id: string }[]>([]);

    open(component: any, data: any = {}): void {
        const id = uuidv4();
        this.popups.push({ component, data, id });
        this.popupContext.next([...this.popups]);
    }

    closeById(id: string): void {
        this.popups = this.popups.filter(popup => popup.id !== id);
        this.popupContext.next([...this.popups]);
    }
    getModalIdByIndex(index: number): string | undefined {
        return this.popups[index]?.id;
    }
    closeAll(): void {
        this.popups = [];
        this.popupContext.next([]);
    }

    popups$(): Observable<{ component: any, data: any }[]> {
        return this.popupContext.asObservable();
    }
}