import { IGoodsExtra, EGoodsExtra, IGoodsCard, IGoodsInformation, ISelect, ISelectedRow } from "./goods.interface";

import { Table, TableRow, Cell } from "../table/table.class";

import { Vendor } from "../vendor/vendor.class";
import { Focus } from "../vendor/focus.class";
import { filter } from "rxjs";

export { Table, TableRow, Cell, Vendor, EGoodsExtra };


export class GoodsExtra implements IGoodsExtra {
    data: Table | null;
    name: string | null;
    type: EGoodsExtra;
    constructor(type: EGoodsExtra, data?: { data?: Table, name?: string }) {
        if (type == EGoodsExtra.table && data && data.data) {
            this.data = data.data;
            this.type = type;
            this.name = "Таблица размеров";
        }
        else {
            this.type = 0;
            this.data = null;
            this.name = null;
        }
    }
}
export class GoodsCard implements IGoodsCard {
    constructor(name: string, link: string, imageUrl: string, information?: { focus?: Focus[], vendors?: Vendor[] }) {

        this.imageUrl = imageUrl;
        this.name = name;
        this.link = link;
        this.vendors = information?.vendors;
        this.focus = information?.focus;
    }
    id: number = 0;
    imageUrl: string;
    name: string;
    link: string;
    focus?: Focus[];
    vendors?: Vendor[];
}

export class Goods implements IGoodsCard, IGoodsInformation {
    id: number = 0;
    imageUrl: string;
    name: string;
    link: string;

    focus?: Focus[];

    vendors?: Vendor[];
    specification: string;
    seoDescription: string;
    usage: string;
    carouselUrl?: string;
    extra?: GoodsExtra;
    buyPreferences: Array<{ type: "vendor" | "table" | "fiting" | "length", tableCol?: number }> = [{ type: "vendor" }, { type: "table", tableCol: 3 }];


    constructor(card: GoodsCard, information: { seoDescription: string, specification: string, usage: string, extra?: IGoodsExtra, buyPreferences?: Array<{ type: "vendor" | "table" | "fiting" | "length", tableCol?: number }> }, carouselUrl?: string) {
        this.seoDescription = information.seoDescription;
        this.id = card.id;
        this.imageUrl = card.imageUrl;
        this.link = card.link;
        this.name = card.name;
        this.focus = card.focus;
        this.vendors = card.vendors;
        this.specification = information.specification;
        this.usage = information.usage;
        this.extra = information.extra;
        if (carouselUrl) {
            this.carouselUrl = carouselUrl;
        }
        if (information.buyPreferences) {
            this.buyPreferences = information.buyPreferences;
        }
    }
    generateSelects(): Array<Select> {
        let temp = new Array<Select>();
        if (this.buyPreferences) {
            this.buyPreferences.forEach(pref => {
                temp.push(Select.generateSelect(pref.type, { table: this.extra?.data, column: pref.tableCol, vendors: this.vendors }));
            });
        }
        return temp;
    }
}
export class Select implements ISelect {
    title: string;
    values: Array<string>;
    subValues: Array<string> = [];
    status: "active" | "transform" | "inactive";
    active: boolean = false;
    type: "vendor" | "table" | "fiting" | "length" | "null" = "null";
    constructor() {
        this.title = "";
        this.values = [];
        this.status = "inactive";

    }
    static fromFitings(title?: string): Select {
        let temp = new Select();
        temp.title = title ?? "Вид";
        temp.values = [
            'прямой 0°',
            'угловой 45°',
            'угловой 90°',
            'штуцер с наружной резьбой'
        ]
        temp.type = "fiting";
        return temp;
    }
    static fromTables(table: Table, column: number, settings?: { title?: string, added?: string }): Select {
        let temp = new Select();
        let headerColumnTemp = column;
        let headerColumn = 0;
        table.headers[0].cells.forEach((item:Cell) => {
            if (headerColumnTemp > 0) {
                headerColumnTemp -= item.colspan;
                headerColumn += 1;
            }
        })
        if (settings?.title) {
            temp.title = settings.title;
        } else {
            temp.title = table.headers[0].cells[headerColumn].text;
        }
        let added = settings ? settings.added : "";
        table.rows.forEach((row:TableRow) => {

            if (!temp.values.includes(row.cells[column].text)) {
                temp.values.push(row.cells[column].text + added);
            }
        });
        temp.type = "table";
        return temp;
    }
    static fromVendors(vendors: Vendor[]): Select {
        let temp = new Select();
        temp.title = "Производитель";
        vendors.forEach((vendor, index) => {
            temp.values.push(vendor.name);
        })
        temp.type = "vendor";

        return temp;
    }
    static generateSelect(type: "vendor" | "table" | "fiting" | "length" | "null", data: { title?: string, table?: Table | undefined | null, column?: number, vendors?: Vendor[] | undefined | null }): Select {
        let temp = new Select();
        switch (type) {
            case "table":
                if (data.table && data.column) {
                    temp = Select.fromTables(data.table, data.column, { title: data.title });
                }
                break;
            case "fiting":
                temp = Select.fromFitings(data.title);
                break;
            case "vendor":
                if (data.vendors) {
                    temp = Select.fromVendors(data.vendors);
                }
                break;
            case "null":
                break;
            case "length":
                break;
        }
        return temp;
    }
}
