@if (card) {
<div class="catalog__card">
    <div class="card__header card__overlay">
        <p class="text-catalog w800 upper ">{{card.title}}</p>
    </div>
    <img class="card__img card__img--{{card.imageAlign}}"   alt="" src="{{card.imageURL}}" loading="eager">
    <div class="card__buttons card__overlay">
        @if(card.button1.type == "popup") {
            <button class="button button--yellow  text-small w600" (click)="clickButton(card.button1.props)">{{card.button1.text}}</button>
        } @if(card.button1.type == "link") {
            <a class="button button--yellow  text-small w600" [routerLink]="card.button1.props[0]">{{card.button1.text}}</a>
        }
        @if(card.button2) {
            @if(card.button2.type == "popup") {
                <button class="button button--transparent text-xsmall" (click)="clickButton(card.button2.props)">{{card.button2.text}}</button>
            } @if(card.button2.type == "link") {
                <a  class="button button--transparent text-xsmall" [routerLink]="card.button2.props[0]">{{card.button2.text}}</a>
            }
        }
       
    </div>
</div>
}