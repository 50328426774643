@if (isLoading && product) {

<div class="product__wrapper">
    <div class="product__image">
        <img alt="" [src]="product.imageURL">
    </div>
    <h2 class="product__header text-large text-dark-blue upper w900">{{product.name}}</h2>
    <div class="information__wrapper">
        <article class="product__information block-specification" [innerHTML]="product.specification"></article>
        <article class="product__information block-usage" [innerHTML]="product.usage"></article>
    </div>
    @if (selects && formGenerated) {

    <form novalidate (ngSubmit)="openPopup()" class="product__form" [formGroup]="clientForm">
        <div class="form__selects">
            @for ( select of selects; track $index ) {
            <div class="form-select">
                <label [for]="'select'+$index" class="upper">{{select.title}}</label>
                <c-select leftImage="/assets/icons/select-left.svg" [options]="select"
                    [formControlName]="'select'+$index"
                    [error]="controlValue('select'+$index)?.invalid && (controlValue('select'+$index)?.dirty || controlValue('select'+$index)?.touched ||  submit)">
                </c-select>
                @if(controlValue('select'+$index)?.invalid && (controlValue('select'+$index)?.dirty ||
                controlValue('select'+$index)?.touched || submit)) {
                <div class="field-errors__product">
                    @if (controlValue('select'+$index)?.errors?.['required']) {
                    <p>Выберите {{select.title.toLowerCase()}}</p>
                    }
                </div>
                }
            </div>
            }
        </div>
        <button id="form-button" class="product-form-button pointer button button--yellow" type="submit">
            <p>Оставить заявку</p>
        </button>
        <div class="product-form-button pointer button button--table" (click)="openTable()">
            Таблица размеров
        </div>
        @if(product.table) {
        <div class="table-wrap" [class.table-close]="!tableOpen">
            <table>
                @if(product.table.headers) {
                <thead>
                    @for (header of product.table.headers; track header.cells) {
                    <tr [class.first-header]="$index==0" [class.second-header]="$index>0">
                        @for(cell of header.cells; track cell) {
                        <th [innerHTML]="cell.text" [colSpan]="cell.colspan"></th>
                        }
                    </tr>
                    }
                </thead>
                }
                @if(product.table.rows) {
                <tbody>
                    @for (row of product.table.rows; track $index) {
                    <tr>
                        @for (cell of row.cells; track $index) {
                        <th [innerHTML]="cell.text" [colSpan]="cell.colspan"></th>
                        }
                    </tr>
                    }
                </tbody>
                }
            </table>
        </div>
        }
    </form>
    }
</div>
}