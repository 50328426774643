import { Injectable } from '@angular/core';
import { map, Observable, of, Subject } from 'rxjs';
import { Product } from '../data/goods/new-goods.class';
import { HttpClient } from '@angular/common/http';
import { products } from '../constants/products';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private productIsLoading = new Subject<{id:string,status:boolean}>();
    productIsLoading$ = this.productIsLoading.asObservable();
    private loadedProducts: Array<{id:string, product:Product}> = [];

    setLoadingStatus(data:{id:string, status:boolean}) {
        this.productIsLoading.next(data);
    }
    getLoadedProducts(id:string):Observable<Product> {
       
        var temp = this.loadedProducts.filter((v)=>v.id==id);
        if(temp.length>0) {
            return of(temp[0].product);
        } else {
            return this.http.get<Product>(`${this.apiUrl}/${id}`).pipe(map(e=> {this.loadedProducts.push({id:id, product:e}); return e}));
        }
    }
    private apiUrl = '/api/data'; // Путь к вашему API

    constructor(private http: HttpClient) { }
}
