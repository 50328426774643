<div class="request-wrapper" >
    <div id="request--form"></div>
    <div class="request__left">
        @if(customText && anotherData) {
            <h3 class="text-xlarge w400">
                <span class="highlight w600">Для заказа {{anotherData.length>1?anotherData[1]:""}}</span>
                <br> напишите номер телефона, и мы с вами свяжемся!
            </h3>
        } @else {
            <h3 class="text-xlarge w400">
                <span class="highlight w600">Для того чтобы оставить заявку,</span>
                <br> напишите номер телефона, и мы с вами свяжемся!
            </h3>
        }
        <img alt="" class="request__image" src="assets/images/8.webp">
    </div>

    <form class="request__right" [formGroup]="requestForm" (submit)="onSubmit()">
        <div class="form__fields">
            <label class="text-xsmall" for="phoneField2">Введите номер телефона</label>
            <div class="input-number">
                <input prefix="+7 " mask="(000) 000 00 00" placeholder="+7" type="text" formControlName="phone" id="phoneField2" (focus)="scroll()">
                @if(_phone?.invalid && (_phone?.dirty || _phone?.touched || submit)) {
                <div class="input-errors  input-errors_paddings">
                    @if(_phone?.errors?.['invalidMsg']) {
                    <p class="input-errors__row input-errors__row_3">❌ {{_phone?.errors?.['invalidMsg']}}</p>
                    }
                    @if(_phone?.errors?.['required'] && !_phone?.errors?.['invalidMsg']) {
                    <p class="input-errors__row input-errors__row_3">❌ Заполните поле</p>
                    }
                </div>
                }
            </div>
        </div>
        <button class=" button button--yellow text-medium" type="submit">Оставить заявку</button>
    </form>


</div>